import React from 'react';
import FooterTitle from './FooterTitle';
import { CONTACT_INFO_EMAIL, CONTACT_INFO_PHONE, CONTACT_INFO_ADDRESS } from '../util/constants';

function ContactInformation() {
    const phoneNumber = "(000) 000-0000"
  return (
    <div className="contact-information-wrapper">
        <FooterTitle title="Contact Information" />
        <p className='contact-information-field'>
            Email: <a className='contact-information-field' href= {"mailto: "+CONTACT_INFO_EMAIL}>{CONTACT_INFO_EMAIL}</a> 
        </p>
        <p className='contact-information-field'>
            Phone Number: <a className='contact-information-field' href= {"tel: "+CONTACT_INFO_PHONE}>{CONTACT_INFO_PHONE}</a>
        </p>
        <p className='contact-information-field'>
            Address: <a className='contact-information-field' href="https://www.google.com/maps/place/Bollywood+For+Life/@37.6933322,-121.902244,17z/data=!3m1!4b1!4m6!3m5!1s0x808fe9a2138e0aed:0xdda365813ce2b7fd!8m2!3d37.6933322!4d-121.902244!16s%2Fg%2F11kn4dggw5?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" target="_blank">{CONTACT_INFO_ADDRESS}</a>
        </p>
    </div>
  );
}

export default ContactInformation;
